import React from "react"
import { SectionContainer } from "src/components/shared/SectionContainer"
import styled from "styled-components"

import {
  CreditCard,
  Users,
  Home,
  BarChart2,
  Bell,
  Calendar,
} from "react-feather"

const features = [
  {
    title: "Gestion des inscriptions",
    subText:
      "Suivez en temps réel les inscriptions grâce à des formulaires customisables",
    icon: <Users />,
  },
  {
    title: "Paiement simplifié",
    subText:
      "OneID offre un outil visuel pour suivre et gérer les paiements en une ou plusieurs fois grâce à notre partenaire Lydia",
    icon: <CreditCard />,
  },
  {
    title: "Répartition des logements",
    subText:
      "Fini le Tetris géant pour organiser la répartition de tous les participants dans les logements, OneID le fait pour vous ",
    icon: <Home />,
  },
  {
    title: "Statistiques en temps réel",
    subText:
      "Des résumés détaillés du profil des participants et des analyses sur les métriques les plus intéressantes",
    icon: <BarChart2 />,
  },
  {
    title: "Notifications pour les participants",
    subText:
      "Plus jamais vous n'entendrez \"je n'ai pas eu l'info\" quand vous enverrez des notifications push à tous les participants",
    icon: <Bell />,
  },
  {
    title: "Organisation du planning",
    subText:
      "Un outil simple et intuitif pour créer et gérer l'emploi du temps de chaque participant",
    icon: <Calendar />,
  },
]

const Element = ({
  title,
  subText,
  icon,
}: {
  title: string
  subText: string
  icon: any
}) => {
  return (
    <FeatureElement>
      <IconContainer>{icon}</IconContainer>
      <FeatureText>
        <FeatureName>{title}</FeatureName>
        <FeatureDescription>{subText}</FeatureDescription>
      </FeatureText>
    </FeatureElement>
  )
}

const Features = () => {
  return (
    <SectionContainer
      title="Fonctionnalités"
      subTitle="Simplification la gestion de votre événement avec tous les outils dont vous avez besoin pour assurer le succès de votre évènement"
      sectionName="features"
    >
      <FeatureGrid>
        <FeatureRow>
          {features.map(({ title, subText, icon }) => (
            <Element title={title} subText={subText} icon={icon} key={title} />
          ))}
        </FeatureRow>
      </FeatureGrid>
    </SectionContainer>
  )
}

export { Features }

const FeatureGrid = styled.div`
  margin: 0 auto;
  width: 100%;
  margin: -${props => props.theme.margins.m6} 0;
`

const FeatureRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const FeatureElement = styled.div`
  display: flex;

  flex-basis: 100%;
  @media (min-width: ${props => props.theme.screens.sm}) {
    flex-basis: 50%;
  }

  position: relative;
  width: 50%;
  margin: ${props => props.theme.margins.m6} 0;
`

const IconContainer = styled.div`
  background: linear-gradient(
    45deg,
    ${props => props.theme.colors.primary} 30%,
    ${props => props.theme.colors.primarylight} 90%
  );
  border-radius: 3px;
  border: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  flex-shrink: 0;
`

const FeatureText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.paddings.p4} 0;
`
const FeatureName = styled.h3`
  font-size: ${props => props.theme.fontSizes.lg};
  font-weight: 600;
`
const FeatureDescription = styled.p`
  margin-top: ${props => props.theme.margins.m2};
  color: ${props => props.theme.colors.secondarydark};
`
