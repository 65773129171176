import React from "react"

import Layout from "components/shared/layout"
import SEO from "components/shared/seo"

import { Hero } from "components/landing/Hero"
import { Features } from "components/landing/Features"
import { Pricing } from "components/landing/Pricing"
import { Secure } from "components/landing/Secure"
import { References } from "src/components/landing/References"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import bugsnagKey from "../../keys/bugsnagKey"

Bugsnag.start({
  apiKey: bugsnagKey,
  plugins: [new BugsnagPluginReact()],
})

//@ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

const IndexPage = () => (
  <ErrorBoundary>
    <Layout>
      <SEO
        title="OneID"
        description="Un moyen simple, efficace et rapide d'organiser et gérer un évènement étudiant."
      />
      <Hero />
      <Features />
      <Secure />
      <References />
      <Pricing />
    </Layout>
  </ErrorBoundary>
)

export default IndexPage
