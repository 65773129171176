import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(name: { eq: "bg" }, extension: { eq: "svg" }) {
        name
        publicURL
      }
      appstore: file(name: { eq: "appstore" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playstore: file(name: { eq: "playstore" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mockup: file(name: { eq: "mockup" }, extension: { eq: "png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <BgContainer bgURL={data.bg.publicURL}>
      <Container>
        <Text>
          <Heading>Votre Gestionnaire d'événements</Heading>
          <SubHeading>
            Un moyen simple, efficace et rapide d'organiser et gérer un
            évènement étudiant.
          </SubHeading>
          <StoreContainer>
            <div className="element">
              <a href="https://itunes.apple.com/fr/app/oneid-ton-identit%C3%A9-num%C3%A9rique/id1437710130?mt=8">
                <Img fixed={data.appstore.childImageSharp.fixed} alt="" />
              </a>
            </div>
            <div className="element">
              <a href="https://play.google.com/store/apps/details?id=com.imagineoneid.oneid">
                <Img fixed={data.playstore.childImageSharp.fixed} alt="" />
              </a>
            </div>
          </StoreContainer>
        </Text>
        <Mockup>
          <Img fixed={data.mockup.childImageSharp.fixed} alt="" />
        </Mockup>
      </Container>
    </BgContainer>
  )
}

export { Hero }

const Container = styled.div`
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
  padding: ${props => props.theme.paddings.p4};

  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: ${props => props.theme.screens.md}) {
    flex-direction: row;
    align-items: inherit;
    text-align: inherit;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: ${props => props.theme.margins.m48} 0 0 0;

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: ${props => props.theme.margins.m32} 0;
    width: 50%;
  }
`

const Mockup = styled.div`
  width: 50%;
  display: flex;
  margin: ${props => props.theme.margins.m24} 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: ${props => props.theme.margins.m48} 0
      ${props => props.theme.margins.m32} 0;
  }
`

const BgContainer = styled.div<{ bgURL: string }>`
  height: 100%;
  overflow: hidden;
  margin-top: -${props => props.theme.margins.m16}; // Matches the margin set for the navBar in layout
  background: none;

  @media (min-width: ${props => props.theme.screens.md}) {
    background: url(${props => props.bgURL}) no-repeat center center;
    background-size: cover;
  }
`

const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.margins.m4} auto;

  .element {
    margin: 0 ${props => props.theme.margins.m4};
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: ${props => props.theme.margins.m4} -${props => props.theme.margins.m4};
  }
`

const Heading = styled.h1`
  color: ${props => props.theme.colors.dark};
  font-size: ${props => props.theme.fontSizes.xl5};
`

const SubHeading = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: ${props => props.theme.fontSizes.lg};
  max-width: 450px;
  font-weight: 300;
  line-height: 1.5rem;
  margin: ${props => props.theme.margins.m4} auto;

  @media (min-width: ${props => props.theme.screens.md}) {
    margin: ${props => props.theme.margins.m4} 0;
  }
`
