import React from "react"
import { SectionContainer } from "src/components/shared/SectionContainer"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const References = () => {
  const data = useStaticQuery(graphql`
    query {
      forumam: file(name: { eq: "logo_forumAM" }, extension: { eq: "png" }) {
        name
        publicURL
      }
      voilzarts: file(
        name: { eq: "logo_voilzarts" }
        extension: { eq: "jpg" }
      ) {
        name
        publicURL
      }
      skz: file(name: { eq: "logo_skz" }, extension: { eq: "jpg" }) {
        name
        publicURL
      }
    }
  `)

  const cards = [
    {
      id: 1,
      imageURL: data.voilzarts.publicURL,
      name: "Voilz'Arts",
      description: "Association Voile des Arts et Métiers",
      participents: 240,
    },
    {
      id: 2,
      imageURL: data.skz.publicURL,
      name: "SKZ",
      description: "Association Ski des Arts et Métiers",
      participents: 1400,
    },
    {
      id: 3,
      name: "Forum A&M",
      imageURL: data.forumam.publicURL,
      description: "Forum Arts et Métiers ",
      participents: 3000,
    },
  ]

  const Card = ({
    id,
    name,
    description,
    participants,
    imageURL,
  }: {
    id: number
    name: string
    description: string
    participants: number
    imageURL: string
  }) => (
    <Figure key={id} imageURL={imageURL}>
      <Participants>
        <span>{participants}</span>
        <span>participants</span>
      </Participants>
      <FigCaption>
        <h4>
          <span>{name}</span>
        </h4>
        <p>{description}</p>
      </FigCaption>
    </Figure>
  )

  return (
    <SectionContainer
      title="Les évènements partenaires de OneID"
      subTitle="Aujourd'hui OneID se développe à l'aide d'évènements de plus en plus
          importants et ambitieux, mais aussi en variant les partenaires pour
          vous permettre de profiter pleinement de tout les avantages de
          l'application"
      sectionName="references"
      maxWidth={"1100px"}
    >
      <Container>
        {cards.map(card => (
          <Card
            id={card.id}
            name={card.name}
            description={card.description}
            participants={card.participents}
            imageURL={card.imageURL}
            key={card.id}
          />
        ))}
      </Container>
    </SectionContainer>
  )
}

export { References }

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Figure = styled.figure<{ imageURL: string }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  width: 290px;
  height: 395px;
  padding: ${props => props.theme.paddings.p5};
  margin: ${props => props.theme.margins.m4};

  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url(${props => props.imageURL});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //font-family: $font-awesome;
  }
  &:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
  &:after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
  }
  &:hover {
    background-size: auto 105%;
    &:after {
      content: "";
      color: white;
      font-size: 72px;
      z-index: 2;
    }
  }
`

const Participants = styled.div`
  position: absolute;
  bottom: 0;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.theme.paddings.p3};
  background-color: ${props => props.theme.colors.label}80;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  span {
    color: white;
    line-height: 1;
    &:first-child {
      font-size: 20px;
      font-weight: 900;
    }
    &:last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
`

const FigCaption = styled.div`
  color: white;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1;
  h4 {
    margin: 0 0 ${props => props.theme.margins.m1};
    font-size: 24px;
    line-height: 1.35;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    span {
      background-color: ${props => props.theme.colors.label}80;
      padding: 0 ${props => props.theme.paddings.p2};
    }
  }
  p {
    margin: 0;
    line-height: 1.5;
  }
`
