import React from "react"
import styled from "styled-components"
import { SectionContainer } from "src/components/shared/SectionContainer"
import { useStaticQuery, graphql } from "gatsby"
import { Button } from "components/shared/atoms/Button"
import { theme } from "../../styles/theme"

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      starterIllus: file(
        name: { eq: "starterPricing" }
        extension: { eq: "svg" }
      ) {
        name
        publicURL
      }
      standardIllus: file(
        name: { eq: "standardPricing" }
        extension: { eq: "svg" }
      ) {
        name
        publicURL
      }
      premiumIllus: file(
        name: { eq: "premiumPricing" }
        extension: { eq: "svg" }
      ) {
        name
        publicURL
      }
    }
  `)

  type Tier = {
    title: string
    color: string
    subheader?: string
    price: string | number
    subPrice: string
    description: { label: string; icon: string }[]
    buttonText: string
    buttonLink: string
    free?: boolean
    illus: string
    order: number
    featured?: boolean
  }
  type Tiers = Record<"starter" | "pro" | "premium", Tier>

  const tiers: Tiers = {
    starter: {
      title: "Standard",
      color: theme.colors.primarylight,
      price: 0,
      subPrice: "",
      description: [
        {
          label: "Nombre d'évènements illimité",
          icon: "all_inclusive",
        },
        {
          label: "Jusqu'à 100 participants",
          icon: "people",
        },
        {
          label: "Un formulaire d'inscription avancé",
          icon: "list_alt",
        },
        {
          label: "Des statistiques sur les inscrits en temps réel",
          icon: "bar_chart",
        },
        {
          label: "Exportation de vos bases de données sous .csv",
          icon: "file_download",
        },
        {
          label:
            "Une App In App pour personnaliser l'expérience de votre évènement",
          icon: "widgets",
        },
        {
          label: "Collaboration en équipe",
          icon: "groups",
        },
      ],
      buttonText: "Télécharger l'app",
      buttonLink: "#",
      free: true,
      illus: data.starterIllus.publicURL,
      order: 1,
    },
    pro: {
      title: "Pro",
      color: theme.colors.primary,
      subheader: "Le plus populaire",
      price: "0,5 %",
      subPrice: "des paiements reçus",
      description: [
        {
          label: "Les options de la formule Standard",
          icon: "military_tech",
        },
        {
          label: "Nombre de participants illimité !",
          icon: "people",
        },
        {
          label: "Paiement en une ou plusieurs fois",
          icon: "payment",
        },
        {
          label: "Répartition des logements",
          icon: "hotel",
        },
        {
          label: "Téléchargement de documents",
          icon: "attachment",
        },
        {
          label: "Personnalisation de l'app",
          icon: "palette",
        },
        {
          label: "Bracelet QR Code (en option)",
          icon: "qr_code_scanner",
        },
      ],
      buttonText: "Nous contacter",
      buttonLink: "mailto:contact@myoneid.fr?subject=Détails du plan Pro",
      illus: data.standardIllus.publicURL,
      featured: true,
      order: 3,
    },
    premium: {
      title: "Premium",
      color: theme.colors.primarydark,
      price: "Sur devis",
      subPrice: "",
      description: [
        {
          label: "Les options de la formule Pro",
          icon: "military_tech",
        },
        {
          label:
            "Une synchronisation sur vos propres bases de données grace à notre API",
          icon: "sync",
        },
        {
          label: "Intégration avec d'autres Saas",
          icon: "import_export",
        },
        {
          label:
            "Générez automatiquement des documents avec les informations de vos clients préremplies",
          icon: "create",
        },
        {
          label: "Vérification d'identité pour toujours plus de sécurité",
          icon: "verified_user",
        },
        {
          label: "Paiement cashless",
          icon: "contactless",
        },
        {
          label: "Billeterie électronique, avec option de paiement mobile",
          icon: "shopping_cart",
        },
      ],
      buttonText: "Nous contacter",
      buttonLink:
        "mailto:contact@myoneid.fr?subject=Détails du plan Entreprise",
      illus: data.premiumIllus.publicURL,
      order: 2,
    },
  }

  const PricingPane = ({
    tier,
    isFeatured,
  }: {
    tier: Tier
    isFeatured?: boolean
  }) => {
    const {
      color,
      title,
      price,
      description,
      buttonText,
      order,
      free,
      illus,
      buttonLink,
      subPrice,
    } = tier
    return (
      <PricingPlan isFeatured={isFeatured} order={order}>
        <div className="image-container">
          <Image src={illus} alt="" />
        </div>
        <Header color={color}>{title}</Header>
        <Features>
          {description &&
            description.map(item => {
              return (
                <FeatureContainer>
                  <FeatureIcon className="material-icons" color={color}>
                    {item.icon}
                  </FeatureIcon>
                  <FeatureItem key={item.icon}>{item.label}</FeatureItem>
                </FeatureContainer>
              )
            })}
        </Features>
        {free || typeof price !== "number" ? null : (
          <Sub color={color}>À partir de</Sub>
        )}
        <Price color={color}>
          {typeof price === "number" ? price + " €" : price}{" "}
        </Price>
        {typeof price === "number" ? (
          <Sub>/ utilisateur</Sub>
        ) : (
          <Sub>{subPrice}</Sub>
        )}
        <Button
          themeStyle={free ? "primary" : "neutral"}
          color={color}
          className="button"
          link={buttonLink}
        >
          {buttonText}
        </Button>
      </PricingPlan>
    )
  }

  return (
    <SectionContainer sectionName="pricing" title="Nos Plans">
      <Container>
        {PricingPane({ tier: tiers.starter })}
        {PricingPane({ tier: tiers.pro, isFeatured: true })}
        {PricingPane({ tier: tiers.premium })}
      </Container>
    </SectionContainer>
  )
}

export { Pricing }

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${props => props.theme.margins.m72};
  flex-wrap: wrap;

  .button {
    margin-top: ${props => props.theme.margins.m10};
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    flex-wrap: nowrap;
  }

  .image-container {
    margin-bottom: ${props => props.theme.margins.m5};
    height: ${props => props.theme.margins.m20};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .material-icons {
    font-family: "Material Icons";
    font-size: 24px;
  }
`

const Image = styled.img`
  max-width: 100%;
  width: ${props => props.theme.margins.m16};
  height: auto;
`

const Header = styled.h3`
  color: ${props => props.color};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.xl2};
`

const Features = styled.ul`
  font-weight: 600;
  letter-spacing: 1px;
  margin: ${props => props.theme.margins.m11} 0
    ${props => props.theme.margins.m6};
  list-style: none;
`

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e1f1ff;
  &:first-child {
    border-top: 0px;
  }
  align-items: "center";
  padding: ${props => props.theme.paddings.p5} 0;
`

const FeatureItem = styled.p`
  font-weight: 300;
  margin-left: ${props => props.theme.paddings.p3};
  text-align: left;
`

const FeatureIcon = styled.span`
  color: ${props => props.color};
`

const Price = styled.div`
  color: ${props => props.color};
  display: block;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: ${props => props.theme.margins.m1};
`
const Sub = styled.div`
  color: ${props => props.color};
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes.sm};
  margin-bottom: ${props => props.theme.margins.m1};
`

const PricingPlan = styled.div<{ isFeatured?: boolean; order: number }>`
  border-bottom: 1px solid #e1f1ff;
  padding: ${props => props.theme.paddings.p5};
  text-align: center;
  border: none;
  border-radius: 10px;

  order: ${props => props.order};
  max-width: ${props => (props.isFeatured ? "350px" : "250px")};
  width: 100%;
  box-shadow: ${props =>
    props.isFeatured ? props.theme.boxShadows.medium : ""};

  @media (min-width: ${props => props.theme.screens.md}) {
    order: inherit;
    max-width: inherit;
    width: inherit;
    box-shadow: ${props =>
      props.isFeatured ? props.theme.boxShadows.large : ""};
  }

  @media (min-width: ${props => props.theme.screens.lg}) {
    flex-basis: 100%;
    padding: ${props =>
      `${props.theme.paddings.p5} ${props.theme.paddings.p4}`};
  }
`
