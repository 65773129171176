import React from "react"
import styled from "styled-components"

interface Props {
  title: string
  subTitle?: string
  sectionName?: string
  bgPaper?: boolean
  children?: React.ReactNode
  maxWidth?: string
}

const SectionContainer = ({
  title,
  subTitle,
  children,
  sectionName,
  maxWidth,
}: Props) => {
  return (
    <section id={sectionName}>
      <Container maxWidth={maxWidth}>
        <Head>
          <Header>{title}</Header>
          <SubHeader>{subTitle}</SubHeader>
        </Head>
        {children}
      </Container>
    </section>
  )
}

export { SectionContainer }

const Header = styled.h2`
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes.xl3};
  color: ${props => props.theme.colors.dark};
`
const SubHeader = styled.p`
  margin: ${props => props.theme.margins.m4} 0;
  font-weight: 600;
  line-height: 1.6;
  color: ${props => props.theme.colors.dark};
`
const Container = styled.div<{ maxWidth?: string }>`
  margin: 0 auto;
  padding: 0 ${props => props.theme.paddings.p6};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "900px")};

  // offset for anchor scrolling
  ::before {
    content: "";
    display: block;
    position: relative;
    width: 0;
    height: 1rem;
    margin-top: -${props => props.theme.margins.m4};
  }
`
const Head = styled.div`
  margin: ${props => props.theme.margins.m28} auto
    ${props => props.theme.margins.m20};
  max-width: 700px;
  text-align: center;
`
