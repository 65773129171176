import React from "react"
import { SectionContainer } from "components/shared/SectionContainer"
import { Emoji } from "components/shared/atoms/Emoji"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const SecureElement = ({
  emoji,
  content,
}: {
  emoji: { label: string; symbol: any }
  content: string
}) => {
  return (
    <Element>
      <Emoji label={emoji.label} symbol={emoji.symbol} fontSize="3rem" />
      <ElementText>{content}</ElementText>
    </Element>
  )
}

const Secure = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "qrCodeIllus" }, extension: { eq: "svg" }) {
        name
        publicURL
      }
    }
  `)
  return (
    <SectionContainer
      title="Sécurité garantie"
      subTitle="Un accent particulier a été porté à la sécurité des données des utilisateurs et de l'organisation. OneID apporte aussi une solution pour identifier rapidement les participants grâce à des bracelets à QR code"
      sectionName="secure"
    >
      <Grid>
        <IllusContainer>
          <img
            src={data.file.publicURL}
            alt="mockup of the OneID app"
            width="100%"
            height="auto"
          />
        </IllusContainer>
        <Elements>
          <SecureElement
            emoji={{ label: "person", symbol: "👶🏼" }}
            content=" Retrouver et s'assurer d'une identité instantanément"
          />
          <SecureElement
            emoji={{ label: "presence", symbol: "🏂" }}
            content="Pointer la présence des tous les inscrits à une activité"
          />
          <SecureElement
            emoji={{ label: "identify", symbol: "🕵🏼" }}
            content="Identifier rapidement les options souscrites"
          />
        </Elements>
      </Grid>
    </SectionContainer>
  )
}

export { Secure }

const Grid = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.screens.sm}) {
    flex-direction: inherit;
  }
`

const IllusContainer = styled.div`
  flex-shrink: 0;
  width: 400px;
  margin-top: ${props => props.theme.margins.m24};

  @media (min-width: ${props => props.theme.screens.sm}) {
    margin: ${props => props.theme.margins.m8} 0 -${props =>
        props.theme.margins.m8} 0;
    width: 60%;
  }
`

const Elements = styled.div`
  display: flex;
  flex-direction: column;
`

const Element = styled.div`
  display: flex;
  flex-direction: row-reverse;
  max-width: 350px;

  @media (min-width: ${props => props.theme.screens.sm}) {
    max-width: inherit;
    flex-direction: row;
  }

  align-items: center;
  margin: ${props => props.theme.margins.m6} 0
    ${props => props.theme.margins.m6} ${props => props.theme.margins.m4};
`

const ElementText = styled.p`
  margin-left: ${props => props.theme.margins.m4};
  font-size: 1.25rem;
`
