import React from "react"
import styled from "styled-components"

const Emoji = ({
  label,
  symbol,
  className,
  fontSize
}: {
  label: string
  symbol: any
  className?: string
  fontSize?: string
}) => (
  <Container
    role="img"
    aria-label={label ? label : ""}
    aria-hidden={label ? "false" : "true"}
    className={className}
    fontSize={fontSize}
  >
    {symbol}
  </Container>
)

export { Emoji }

const Container = styled.span<{ fontSize?: string; height?: string }>`
  font-size: ${props => (props.fontSize ? props.fontSize : "1rem")};
`
